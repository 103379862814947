<template>
  <div
    class="container sumBlock"
    v-title
    :data-title="$t('i18n.samedayWorkBillStatistics')"
  >
    <div id="outer-title">{{ $t("i18n.samedayWorkBillStatistics") }}</div>
    <el-radio-group class="sumBlock">
      <el-radio-button>
        <span>{{ $t("i18n.todayBillTotal") }}</span>
        <span class="num">{{ statistics.billsSum }}</span>
      </el-radio-button>
      <el-radio-button>
        <span>{{ $t("i18n.currentFinishBill") }}</span>
        <span class="num">{{ statistics.billsComplete }}</span>
      </el-radio-button>
      <el-radio-button>
        <span>{{ $t("i18n.timeOutBill") }}</span>
        <span class="num">{{ total }}</span>
      </el-radio-button>
    </el-radio-group>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
export default {
  name: "WorkBillStatistics",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      billModelData: [],
      staffStatus: [],
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "bill_number",
          label: "orderNumber",
          align: "center",
        },
        {
          prop: "bill_model",
          label: "orderCategory",
          align: "center",
          formatter: (row) => {
            return t(
              "i18n." +
                state.billModelData.filter((item) => {
                  return item.value == row.bill_model;
                })[0].code
            );
          },
        },
        {
          prop: "alias",
          label: "projectAlias",
          width: "300",
        },
        {
          prop: "enumber",
          label: "elevatorNo",
          align: "center",
        },
        {
          prop: "billstatus",
          label: "currentStatus",
          align: "center",
          formatter: (row) => {
            let current = state.staffStatus.filter((item) => {
              return item.value == row.billstatus;
            });
            return current.length === 0
              ? t("i18n.notSynchronized")
              : t("i18n." + current[0].code);
          },
        },
        {
          prop: "actor",
          label: "handler",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      statistics: {},
    });

    const getBillModelData = () => {
      proxy.$api.common.getLineageByCode("billModel").then((res) => {
        state.billModelData = res.data.lineages;
      });
    };
    getBillModelData();

    const getStaffStatus = () => {
      proxy.$api.common.getLineageByCode("current_state").then((res) => {
        state.staffStatus = res.data.lineages;
      });
    };
    getStaffStatus();

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.maintenance.getBillByDate(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const initData = async () => {
      const { data } = await proxy.$api.maintenance.getBillSumByDate();
      state.statistics = data;
    };
    initData();

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>

<style lang="scss">
.sumBlock {
  margin: 0 auto 10px;
  text-align: center;
  display: block;
  .el-radio-button {
    .el-radio-button__inner {
      width: 188px;
      height: 82px;
      border: none;
      color: $white;
      &:not(&:last-child) {
        margin-right: 3px; /* no */
      }
      span {
        display: block;
        font-size: $font + 2;
        &:last-child {
          font-size: $font * 2 + 8;
          margin-top: 10px;
        }
      }
    }
    &:nth-child(1) {
      .el-radio-button__inner {
        background: $blue_color;
      }
    }
    &:nth-child(2) {
      .el-radio-button__inner {
        background: $orange_color;
      }
    }
    &:nth-child(3) {
      .el-radio-button__inner {
        background: $green_color;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .sumBlock {
    .el-radio-button {
      .el-radio-button__inner {
        width: unset;
        height: unset;
        padding: 5px;
        span {
          font-size: $font;
          &:last-child {
            font-size: 20px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
</style>
